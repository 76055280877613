import Vue from "vue";
import axios from "axios";
import moment from "moment";

const endpoint = "/api/atendimento";
const tipoCanal = ["chatbot", "whatsapp", "email", null, "facebook", "sms", null, "telefonia", "telegram", "whatsapp", "instagram", null, null, null, null, "whatsapp", null, null];
const canais = {
	default: {
		icon: "far fa-sticky-note"
	},
	whatsapp: {
		canal: "WhatsApp",
		icon: "fab fa-whatsapp"
	},
	email: {
		canal: "E-mail",
		icon: "fas fa-envelope",
		size: .85
	},
	facebook: {
		canal: "Facebook",
		icon: "fab fa-facebook-square",
		size: .95
	},
	instagram: {
		canal: "Instagram",
		icon: "fab fa-instagram"
	},
	chatbot: {
		canal: "ChatBot",
		icon: "fas fa-robot",
		size: .8
	},
	sms: {
		canal: "SMS",
		icon: "fas fa-comment",
		size: .9
	},
	telefonia: {
		canal: "Telefonia",
		icon: "fas fa-phone flip-h",
		size: .7
	},
	telegram: {
		canal: "Telegram",
		icon: "fab fa-telegram-plane"
	}
};

const statusIcons = {
	clock: "far fa-clock",
	sent: "fas fa-check",
	delivered: "fas fa-check-double",
	read: "fas fa-check-double read",
	error: "fas fa-times-circle text-danger",
	deleted: "fas fa-ban text-danger"
};

class Atendimento extends Vue {
	constructor(obj) {
		super({
			data: {
				Id: null,
				Protocolo: null,
				UsuarioId: null,
				CanalId: null,
				SetorId: null,
				Canal: {
					Id: null,
					Enum: null,
					Tipo: null,
					Nome: null,
					Icon: null,
					Color: null,
					Size: null,
					Identificador: null
				},
				ClienteId: null,
				Cliente: null,
				ClienteTelefone: null,
				DataPrimeiraMensagem: null,
				DataUltimaMensagem: null,
				DataUltimaLeitura: null,
				DataAgendamento: null,
				DataAgendamentoExpirado: null,
				DataInicioEspera: null,
				DataInicioInteracao: null,
				NovasMensagens: 0,
				Status: null,
				Inicializacao: null,
				TermosDeBusca: null,
				OrigemUltimaMensagem: null,
				StatusUltimaMensagem: null,
				Editor: {
					Mensagem: null,
					Arquivos: [],
					Privado: false,
					Para: null,
					Cc: null,
					Assunto: null,
					EncaminharPara: null
				},
				DadosDiscador: null,
				EmProcessamento: false,
				Tags: []
			}
		});
		this.applyObj(obj);
	}
	applyObj(obj) {
		Object.keys(this.$data).forEach(key => {
			if (key == "Canal") {
				let canalConfig = obj ? canais[tipoCanal[obj.CanalTipo] ?? "default"] : null;
				this.Canal = {
					Id: obj?.CanalId,
					Enum: obj?.CanalTipo,
					Tipo: canalConfig?.canal,
					Nome: obj?.Canal,
					Icon: canalConfig?.icon,
					Color: canalConfig?.color,
					Size: canalConfig?.size,
					Identificador: tipoCanal[obj.CanalTipo] ?? "default"
				};
				if (!this.Canal.Tipo || !["ChatBot", "WhatsApp", "E-mail"].includes(this.Canal.Tipo)) this.Editor.Arquivos = null;
				else if (this.Canal.Tipo && ["ChatBot", "WhatsApp", "E-mail"].includes(this.Canal.Tipo) && !this.Editor.Arquivos) this.Editor.Arquivos = [];
				return;
			}
			if (key == "Editor") return;
			if (!obj) {
				this[key] = null;
				return;
			}
			if (["DataPrimeiraMensagem", "DataUltimaMensagem", "DataUltimaLeitura", "DataAgendamento", "DataAgendamentoExpirado", "DataInicioEspera", "DataInicioInteracao"].includes(key)) {
				this[key] = obj[key] ? moment(obj[key]) : null;
				return;
			}
			this[key] = obj[key];
		});
	}
	async refresh(atendimento = null) {
		if (atendimento == null) {
			atendimento = await axios.get(`${endpoint}/${this.Id}/refresh`).then(response => {
				return response.data;
			});
		}
		this.applyObj(atendimento);
	}
	static async getAtendimentos() {
		return await axios.get(`${endpoint}/meus-atendimentos`).then(response => {
			return (response?.data ?? []).map(atendimento => new Atendimento(atendimento));
		});
	}
}

class Mensagem extends Vue {
	constructor(obj) {
		super({
			data: {
				Id: null,
				Mensagem: null,
				ContextoDaMensagem: null,
				Remetente: null,
				Assunto: null,
				Origem: null,
				TipoCanal: null,
				Cliente: null,
				Atendente: null,
				MinhaMensagem: false,
				Data: null,
				Status: null,
				StatusIcon: null,
				Privado: false,
				Midias: {
					Url: null,
					Tipo: null,
					Nome: null
				},
				ErrorCode: null,
				ErrorMessage: null,
				isBodyVisible: false
			},
			watch: {
				Status() {
					this.StatusIcon = statusIcons[this.Status];
				}
			}
		});
		if (obj == null) return;
		Object.keys(this.$data).forEach(key => {
			if (key == "Data") {
				this.Data = moment(obj.Data);
				return;
			}
			if (key == "TipoCanal" && obj.TipoCanal) {
				this.TipoCanal = tipoCanal[obj.TipoCanal];
				return;
			}
			if (key == "Midias") {
				this.Midias = (obj.Midias ?? []).map(midia => new Midia(midia));
				return;
			}
			if (obj[key] == null) return;
			this[key] = obj[key];
		});
	}
	static async getChat(atendimentoId) {
		return await axios.get(`${endpoint}/${atendimentoId}/chat`).then(response => {
			return {
				DataUltimaLeitura: response?.data?.DataUltimaLeitura ? moment(response?.data?.DataUltimaLeitura) : null,
				Mensagens: (response?.data?.Mensagens ?? []).map(mensagem => new Mensagem(mensagem))
			};
		});
	}
}

class Midia extends Vue {
	constructor(obj) {
		super({
			data: {
				Url: null,
				Tipo: null,
				Nome: null
			}
		});
		if (obj == null) return;
		Object.keys(this.$data).forEach(key => {
			if (obj[key] == null) return;
			this[key] = obj[key];
		});
	}
}

class Cliente extends Vue {
	constructor(obj) {
		super({
			data: {
				Id: null,
				RazaoSocial: null,
				CnpjCpf: null,
				Email: null,
				Telefone: null,
				Origem: null,
				Cep: null,
				Endereco: null,
				Complemento: null,
				Municipio: null,
				UF: null,
				DescadastradoDeCampanhas: false
			}
		});
		if (obj == null) return;
		Object.keys(this).forEach(key => {
			if (obj[key] == null) return;
			this[key] = obj[key];
		});
	}
}

export {
	Atendimento as default,
	Mensagem,
	Cliente
};